$sidebar-width: 55px;
$sidebar-width-expanded: 240px;

.layout {
  min-width: 100% !important; // fix overflow on mobile
  overflow: hidden;
}

.tab-row {
  display: flex;
  flex-direction: row;
  width: 100% !important;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.claim-show-sidebar {
  max-width: 260px;
  width: 100%;
  margin: 0 0 0 1.5em;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.tab-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clearListDot,
.clearListDot * {
  list-style: none;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: -20px !important;
    margin-bottom: 20px !important;
  }
}

.list-page {
  .MuiPaper-rounded {
    box-shadow: none !important;
  }
}

.widget {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  margin-right: 8px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
}

.overflow-manage {
  overflow: auto !important;
  max-width: 100% !important;
}

.activity-create {
  .MuiToolbar-gutters {
    background-color: transparent !important;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.-inline {
    box-shadow: none !important;
    margin-bottom: 0 !important;
    .MuiPaper-rounded {
      box-shadow: none !important;

      .MuiCardContent-root {
        padding: 0 !important;
      }
    }
  }
}

.profile-tabs {
  @media (min-width: 800px) {
    margin-bottom: 2rem;
  }
}

.greeting {
  text-align: center;

  @media (max-width: 800px) {
    text-align: left;
  }
}

.personalized {
  text-align: right;

  @media (max-width: 800px) {
    text-align: left;
    margin-bottom: 1rem !important;

    > label {
      margin-left: 0;
    }
  }
}

#main-content {
  padding: 1.75rem;
  background-color: #f9fbff;
  position: relative;
  width: 100%;
  max-width: calc(100%);
  box-sizing: border-box;
  transition: max-width 0.22s ease-in-out;

  @media screen and (min-width: 600px) {
    max-width: calc(100% - $sidebar-width-expanded);

    .-closedSideBar & {
      max-width: calc(100% - 60px);
    }
  }
}

.list__item {
  display: flex;
  flex-direction: row;

  &.-active-user {
    border: 2px solid #32c587;
  }

  @media screen and (min-width: 600px) {
    .-closedSideBar & {
      min-width: 1000px;
    }
  }
}

.App {
  width: 100%;
  max-width: 100%;

  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
  }

  .MuiAppBar-colorSecondary {
    background-color: #191a42;
    border-bottom: 1px solid #5f84e1;
    box-shadow: none;
  }

  .MuiGrid-item .MuiInputBase-formControl,
  .MuiGrid-item .MuiFormControl-marginDense {
    width: 100%;
  }

  .MuiDrawer-root {
    height: auto !important;
  }

  .MuiDrawer-paper {
    background: linear-gradient(261.31deg, #5229c5 -29.57%, #0f82e0 121.11%);

    .MuiListItemIcon-root {
      color: white;
    }

    .MuiMenuItem-root {
      color: #c3dff7;
      min-height: 44px;
      margin-bottom: 2px;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      background-color: transparent;
    }

    .MuiMenuItem-root[aria-current="page"] {
      color: white;
      background: #5f84e1;
    }
  }

  .MuiButton-textPrimary {
    background-color: var(--primary);
    color: white;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: var(--secondary);
    }
  }
  .MuiButton-containedPrimary {
    color: white;
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      background-color: #0f172a;
      color: white;
    }

    .MuiTableSortLabel-root {
      &:hover {
        color: var(--secondary);

        .MuiTableSortLabel-icon {
          color: var(--secondary) !important;
        }
      }
      .MuiTableSortLabel-icon {
        color: white !important;
      }
    }
    .MuiTableSortLabel-active {
      color: var(--secondary);

      .MuiTableSortLabel-icon {
        color: var(--secondary) !important;
      }
    }
  }

  .MuiFormHelperText-contained {
    display: none;
  }

  .dashboard-container {
    flex-direction: column;
    display: flex;

    .flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: -webkit-fill-available;
      margin-bottom: 1rem;

      .MuiCardContent-root.RaCardContentInner-root-51,
      .MuiCardContent-root.RaCardContentInner-root-72 {
        padding-left: 0 !important;

        .MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-marginDense {
          display: none !important;
        }
      }
    }
  }

  // Card Styles
  // Dashboard rounded card changes
  .MuiPaper-outlined.MuiPaper-rounded {
    border-radius: 8px;
    position: relative;
    margin-bottom: 10px;

    .MuiGrid-container {
      .claim-creditor {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0.2rem;
        color: rgb(117, 117, 117);
      }

      .claim-debtor {
        display: flex;
        text-align: left;
        padding-left: 0.5rem;
        color: rgb(117, 117, 117);
      }

      .claim-creditor,
      .row .claim-debtor {
        > p:first-of-type {
          padding: 0.35rem 0.6rem;
          border-radius: 0.55rem;
          margin-right: 0.8rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.RaTopToolbar-root-97,
.RaTopToolbar-root-57 {
  margin-bottom: 1rem;
  @media (max-width: 959.95px) {
    padding-right: 0px !important;
  }
}

#main-content {
  padding: 1.75rem;
  background-color: #f9fbff;
  position: relative;
  width: 100%;
  max-width: calc(100%);
  box-sizing: border-box;
  transition: max-width 0.25s ease-in-out;

  @media screen and (min-width: 600px) {
    max-width: calc(100% - $sidebar-width-expanded);

    .-closedSideBar & {
      max-width: calc(100% - 80px);
    }
  }
}

.filter-adjust {
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: flex-end;
  }
}

.list__item {
  display: flex;
  flex-direction: row;
  min-width: 900px;

  @media screen and (min-width: 600px) {
    .-closedSideBar & {
      min-width: 1000px;
    }
  }
}

.flex-between {
  // for text and number alignment - see sidebar
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.MuiFilledInput-inputHiddenLabel {
  min-height: 27px;
}

.ql-editor,
.MuiFilledInput-inputMarginDense {
  &::before {
    border-bottom: 1px solid transparent;
  }
}

.MuiToolbar-root.MuiToolbar-regular.RaListToolbar-toolbar-56.MuiToolbar-gutters {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10px 0 20px;

  .MuiButton-text,
  a {
    width: 150px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

div[role="listbox"] strong {
  font-weight: 400;
}

.logoBadge {
  display: block;
  position: relative;
  width: 100%;
  height: 3rem;
  margin-left: -0.4rem;
  cursor: pointer;
  text-decoration: none;
  background: url(../public/logo512.png) no-repeat 100% 50%;
  background-size: 80%; /* Ensure the background area is completely covered by the background image */
  background-position: left;

  &.open {
    margin-left: -0.3rem;
    margin-bottom: 0;
  }
}

.logoText {
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: #fff;
  display: block;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-left: 1rem;

  &.closed {
    display: none;
  }
}

.MuiChip-root {
  height: auto !important;
}

.MuiPaper-rounded span > .ra-field.ra-field-undefined {
  position: relative;
}

.filter-field[data-source="contacts"] > .MuiTextField-root {
  width: 50%;
  min-width: 260px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.btn-margin {
  margin: 0 0.5rem !important;
}

.claimHeaderBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 3%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  align-items: center;
  overflow: hidden;

  .bar-item {
    min-width: 120px;
    position: relative;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 93%;

    .bar-item {
      min-width: auto;
      flex-wrap: auto;
      width: 93%;

      &:not(:first-child) {
        margin-bottom: 10px;
      }

      > span {
        width: 100%;
      }
    }
  }
}

.align-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  padding-top: 10px !important;
  overflow: hidden;

  > a {
    margin-right: 0.5rem;
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    margin-bottom: 1rem;
    flex-direction: column;

    > a {
      border-radius: 8px !important;
      margin-bottom: 1rem;
      min-width: fit-content;
      width: 90%;
      max-width: 100%;
      color: white;
      margin-right: 0;
    }
  }

  &.-alt {
    padding: 20px 0 30px !important;
    > .btn-margin:first-of-type {
      margin-left: 0 !important;
    }
  }
}

.content-flexCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.MuiCardContent-root {
  // fixes padding on card
  padding-bottom: 0 !important;
  padding: 1rem !important;
  min-height: 50px;
}

.small-form {
  .MuiCardContent-root {
    padding: 0 !important;
  }
  .MuiToolbar-regular {
    margin-top: 0;
  }

  .MuiToolbar-gutters {
    background: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: center;
    justify-content: flex-end;

    > div {
      display: block;

      button {
        width: 100%;
      }
    }
  }
}

.MuiAccordionSummary-root {
  min-height: 0 !important;
}

.MuiAccordion-root:before {
  // hides weird extra border in accordion
  background-color: transparent !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  text-align: justify;
}

.keepCardStyle {
  width: 100%;
}

.removeShowCard .MuiPaper-rounded:first-of-type:not(.keepCardStyle) {
  background: none;
  border: none;
  box-shadow: none;

  > .MuiCardContent-root {
    background: transparent;
    border: none;
    padding: 0 !important;
  }
}

.list-page {
  .MuiPaper-root.MuiCard-root {
    @media screen and (max-width: 1024px) {
      overflow: hidden;
      overflow-x: scroll;
    }
  }

  > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    @media screen and (max-width: 1024px) {
      flex-direction: column;

      > form {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin: 1rem 0;

        > div,
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
    }

    @media (min-width: 1024px) {
      > div {
        width: 27%;
      }
      div > button {
        margin-right: 1rem;
      }
    }
  }
}

.MuiButton-iconSizeMedium {
  margin-left: 0 !important;
  margin-right: 0 !important;

  > .MuiAvatar-root {
    margin-right: 8px;
  }
}

.list-item-activity {
  .col {
    display: flex;
    flex-direction: column;
  }

  span {
    display: block;
  }

  .date {
    color: #5229c5;
    font-size: 12px;
    font-weight: bold;
  }

  .company {
    font-size: 14px;
    font-weight: "bold";
  }

  .reference {
    font-size: 14px;
    color: rgb(117, 117, 117);
    margin: 5px 0;
  }
}

.topG-toolbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .comment-wrapper {
    width: 70%;
    flex-basis: 70%;
    padding-right: 2rem;
    overflow: hidden;

    .svg-resize {
      div:first-of-type {
        max-width: 300px;

        > svg {
          max-width: 60px;
          max-height: 60px;
        }

        > p {
          font-size: 1rem;
        }
      }
    }
  }

  .dog {
    width: 30%;
    flex-basis: 30%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .toggle-btn {
      min-width: 200px;
    }
  }
}

.handle {
  width: 100%;
  height: 20px;
  position: absolute;
  top: -15px;
  background-color: #5229c5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: -3px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  transition: all 0.8s ease-in-out;

  &:hover {
    background-color: darken(#5229c5, 20%);
  }
}

.aircallPhone {
  position: fixed;
  right: 35px;
  bottom: 0;
  bottom: -100%;
  z-index: -1;
  transition: bottom 0.2s ease-in-out;
  border: 3px solid #32c587;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  z-index: 9999;

  iframe {
    border: 0;
    background: #fff;
    display: none;
  }

  @media (max-width: 600px) {
    display: none;
  }

  &.-active {
    iframe {
      display: block;
    }
  }

  &.-open {
    bottom: 0;

    @media (max-width: 600px) {
      display: block;
    }
  }
}

.claim-court-buttons {
  display: flex;
  flex-direction: column;

  button {
    margin: 0 0 1rem;
    text-align: right;
    background-color: #0f82e0;
    transition: all 0.8s ease-in-out;
    will-change: transform;
    transform: translateX(300px);
    visibility: hidden;

    &:first-of-type {
      background-color: rgb(163, 42, 56);
      transition-delay: 200ms;
    }

    &:last-of-type {
      margin-right: 0;
      background-color: rgb(22, 114, 170);
      margin-bottom: 0;
      transition-delay: 400ms;
    }
  }

  &.show {
    // animations
    width: 30%;
    button {
      visibility: visible;
      transform: translateX(0);

      &:last-of-type {
        transform: translate(0, 0);
      }
    }
  }
}

.MuiButton-iconSizeMedium {
  transition: all 0.5s ease-in-out;
  will-change: transform;

  .close & {
    transform: rotate(180deg);
  }
}

.relativity {
  position: relative;
  width: 100%;
}

.icon-position {
  height: 12px;
  width: 12px;
  position: relative;
  top: 2px;
}

.overflow-control {
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
}

.paymentPlan-rates {
  position: absolute;
  right: 0;
  bottom: 50px;

  @media (max-width: 600px) {
    position: relative;
  }
}

.paymentPlan-widget {
  position: absolute;
  right: -25px;
  top: -10px;
  background: rgb(172, 118, 56);
  color: #fff;
  font-weight: bold;
  padding: 15px 30px;
  margin-left: 3px;
  margin-bottom: 15px;
  font-size: 14px;
  border-radius: 0;
}

.payment-plan-total {
  position: absolute;
  padding: 15px 30px;
  left: -10px;
  right: 0;
  bottom: -25px;
  width: calc(100% - 22px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #5229c5;

  span {
    color: #fff;
    font-weight: bold;
  }
}

.full-width-input,
.full-width-input {
  margin: 0 10px;

  div {
    width: 100% !important;
  }
}

.grid-helper {
  padding: 18px;
  align-items: center;
}

.tab-col {
  overflow: hidden;
  @media (max-width: 1024px) {
    overflow-x: scroll;
  }
}

.toggle-quick-actions {
  z-index: 9999;
  background-color: rgb(243, 246, 247) !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;

  span.close-open {
    position: absolute;
    width: 5px;
    height: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #5229c5;
    border-radius: 30px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: #5229c5;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 30px;
      transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }
  }
  &.-open {
    span.close-open {
      width: 25px;
      height: 3px;

      &::before {
        top: 6px;
        width: 15px;
        height: 3px;
        transform: rotate(45deg) translate(-2px, 0);
      }

      &::after {
        bottom: 6px;
        width: 15px;
        height: 3px;
        transform: rotate(-45deg) translate(-2px, 0);
      }
    }
  }

  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

.modal-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
}

// Global input style override
.MuiFilledInput-marginDense {
  border-radius: 8px !important;
  &::before {
    border-bottom-width: 0 !important;
  }
}

.ql-editor {
  min-height: 120px;
  border-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.ra-rich-text-input .ql-editor::after {
  background-color: #5229c5 !important;
  height: 0 !important;
}

.ra-rich-text-input .ql-editor::after,
.MuiFilledInput-underline:after {
  border: 2px solid #5229c5 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.elevated-card {
  justify-content: space-between;
  position: fixed !important;
  bottom: 28px;
  width: 240px;
  right: 28px;
  background: white !important;
  padding: 5px 25px;
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}

.assigned-user,
.active-user {
  max-width: 35px;
  width: 35px;
  height: 35px;
  max-height: 35px;
  border: 2.5px solid #32c587;
  border-radius: 30px;
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-left: 0.75rem;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #32c587;
    z-index: -1;
    width: 28px;
    height: 28px;
    border-radius: 30px;
  }
}

.assigned-user--operator {
  border-color: #8368cd;
  max-width: 20px;
  width: 20px;
  height: 20px;
  max-height: 20px;
  font-size: 11px;

  &::before {
    background-color: #8368cd;
    width: 20px;
    height: 20px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.item-wrapper {
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 9999;
  padding: 10px 1rem;
  width: 100%;

  .active-user {
    height: 30px;
    width: 30px;
    z-index: 9999;
    // margin-left: -10px;
    font-size: 10px;
    border: 2.5px solid var(--primary);
    transform: scale(1);
    animation: pulse 2s infinite;

    &:first-of-type {
      margin-left: 0;
      // z-index: 1000;
      // opacity: 0.5;
    }

    &:last-of-type {
      z-index: 9999;
    }

    &::before {
      background: var(--primary);
      height: 25px;
      width: 25px;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  padding: 6px !important;
}

@keyframes flight {
  0% {
    transform: translate(-180px, -250%);
  }
  100% {
    transform: translate(180px, 250%);
  }
}

.next-flight {
  position: absolute;
  transform: translate(-180px, -250%);
  animation-name: flight;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}

.airCall-btn-menu {
  position: absolute;
  right: 25px;
  bottom: 25px;
  width: 260px;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  flex-direction: row !important;
  justify-content: space-between !important;
  // transition: 0.25s ease-in-out !important;

  // button {
  //   // &:first-of-type {
  //   //   background-color: #e93c52;
  //   // }
  // }
}

.you-got-mail {
  background-color: rgba(
    230,
    86,
    0,
    0.25
  ) !important; //var(--warning) !important;
}

.forderung-arrow {
  position: absolute;
  width: 35px !important;
  right: -30px;
  transition: all 0.25s ease-in-out !important;
  will-change: transform;
  transform: translateY(-50%) rotate(180deg);
  top: 50%;

  &.-open {
    transform: translateY(-50%) rotate(0deg);
  }
}

.lessPadding {
  padding: 12px !important;
}

.suggestion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  z-index: 999;

  .active-user {
    width: 20px;
    height: 20px;
    margin-left: 0;
    margin-right: 0.75rem;
    font-size: 8px;
  }
}

.topGInput__highlighter {
  background-color: rgb(206, 228, 229);
}
.topG__input,
.topG__highlighter {
  border-radius: 4px;
  padding: 10px;
  margin: 1px !important;
  outline: none;
}
.topG__highlighter {
  padding: 8px;
  padding-top: 9px !important;
}
.topG__highlighter__caret {
  visibility: hidden;
}
.topG__suggestions {
  li {
    margin: 6px 4px;
    font-size: 13px;
  }
}

.topGInput {
  width: 100%;
  .topG--multiLine {
    border: none !important;
    border-color: transparent;
    width: 100%;
    border-radius: 8px !important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.04);
    min-height: 100px;

    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }

    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: none;
      border: 2px solid #5229c5 !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    &:active:after,
    &:focus:after,
    &:target:after,
    &:hover:after {
      transform: scaleX(1);
    }
  }

  .topG__highlighter {
    strong,
    span {
      font-weight: bold;
      left: 0;
      background: rgba(82, 41, 197, 0.35);
    }
  }

  textarea {
    border: none !important;
  }
}

.multiLineInput {
  width: 100%;
  padding: 10px;
  margin: 1px !important;
  outline: none;
  margin: 1rem 0 !important;

  .MuiInputBase-root {
    min-height: 50px;
    padding: 10px !important;
    border: none !important;
    border-color: transparent !important;
    width: 100%;
    border-radius: 8px !important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.04);

    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
      border-bottom: transparent !important;
    }

    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: none;
      border: 2px solid #5229c5 !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    &:active:after,
    &:focus:after,
    &:target:after,
    &:hover:after {
      transform: scaleX(1);
    }
  }
}

.buttons-align-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.id-wrapper {
  padding: 15px;
  position: absolute;
  right: -35px;
  top: 0px;
  background: #5229c5;

  span {
    color: #fff;

    &:last-of-type {
      font-weight: bold;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    left: 0;
  }

  &.-secondary {
    @media (max-width: 650px) {
      width: 100%;
      left: 0;
      right: auto !important;
    }
  }

  &.-primary {
    @media (max-width: 650px) {
      width: 100%;
      left: 0;
      position: relative;
      right: auto !important;
    }
  }
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 !important;

  .MuiTablePagination-spacer {
    flex: 0 !important;
  }

  div button {
    margin: 0 1rem;
  }
}

// paymnet show hack fix - sorry not sorry
.heightForDesktop {
  @media (min-width: 650px) {
    height: 48px;
  }
}

.download-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1rem;

  &.--multiple {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    margin: 0;
    width: 100%;

    button {
      max-width: 48%;
      min-width: 48%;
    }

    @media (max-width: 800px) {
      flex-direction: column;

      button {
        max-width: 100%;
        min-width: 100%;
        margin-bottom: 1rem;
        background: var(--primary);
        color: white;
      }
    }
  }
}

button {
  @media (max-width: 600px) {
    border-radius: 4px !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.8125rem !important;
    line-height: 1.75 !important;
    font-weight: 500 !important;

    &:hover {
      border-radius: 4px !important;
    }
  }
}

.tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.desktop-hide {
  @media (min-width: 600px) {
    display: none !important;
  }
}

.mobile-hide {
  @media (max-width: 600px) {
    display: none !important;
  }
}

.click-indicator {
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 4px;

  &:hover {
    background: darken(white, 5%);
  }
}

.integration-list {
  .MuiToolbar-gutters {
    .MuiToolbar-regular {
      width: auto !important;

      button {
        margin: 0 1rem;
      }
    }
  }
}

.toolbar-extension {
  @media (min-width: 800px) {
    min-width: 400px !important;
  }
}

.label {
  font-size: 1rem !important;
}

.comment-innerhtml {
  > b.mention {
    background: var(--primary);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    color: white;
  }
}
.comment-innerhtml pre {
  white-space: normal;
}

.force-width {
  > div {
    width: 100% !important;
  }
}

header {
  .MuiAvatar-root {
    border: 2px solid var(--primary) !important;
    background-color: var(--primary) !important;
  }
  .MuiAvatar-img {
    border-radius: 50%;
    width: 35px !important;
  }
}

.show-page .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  flex: 1 1 !important;
}

.file-list-item {
  padding: 5px 30px 5px 15px !important;
  overflow: hidden !important;

  @media (max-width: 1024px) {
    padding: 15px 17px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    background-color: #f3f4f6 !important;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e7eb !important;
    }

    .MuiListItemIcon-root:not(.file-icon),
    .MuiListItemText-root:not(.file-icon) {
      width: 100% !important;
      align-self: flex-start !important;
    }

    .MuiListItemIcon-root,
    .MuiListItemText-root {
      padding-left: 2rem !important;
    }

    .file-icon {
      padding-left: 1rem !important;
    }

    .file-date {
      padding-left: 1.5rem !important;
    }

    .item-checkbox {
      padding-left: 0 !important;
    }
  }
}

.ops-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.claim-show-page {
  .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded:nth-of-type(
      1
    ) {
    box-shadow: none !important;
  }

  .MuiGrid-item
    .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }
}

.traffic-stats {
  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
